import jspdf from "jspdf";
import "jspdf-autotable";
import store from "@/store/index";
import pdf2base64 from "pdf-to-base64";
import axios from "axios";
import moment from "moment";

export const pc_print = (data) => {
  var nom_impresora = "ZDesigner GT800 (EPL)";

  var ticket = {
    impresion: [
      {
        impresora: nom_impresora,
        formato: "pedido.lrf",
        mesa: data.modelo,
        responsable: data.precio,
        fecha: data.id,
        total: "",
        items: "",
      },
    ],
  };
  console.log(ticket);
  var a = reporte(ticket, "codigo").then((r) => {
    return r;
  });
  return a;
};

function reporte(data, name) {
  var a = new Promise((resolve, reject) => {
    var ip = store.state.configImpresora.ip_cocina;
    console.log(ip);
    var printWindow = window.open(
      "http://localhost:8082/printreport?result=" + btoa(JSON.stringify(data)),
      name,
      "left=5000,top=5000,width=0,height=0"
    );
    setTimeout(function() {
      printWindow.close();
      resolve(true);
    }, 1000);
  });

  return a;
}
